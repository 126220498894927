<template>
  <el-card class="routerBox">
    <pageTable 
      :data="companyList"
      @sort-change="sortChange"
      ref="table"
      v-loading="isLoading"
      :default-sort="{ prop: 'name', order: 'descending' }"
      :searchParams.sync="searchParams"
      :row-class-name="tableRowClass"
      @searchEvent="getCompanyArray()"
      @clearEvent="clearHandler()"
      @updateTable="getCompanyArray()">
      <template #searchForm>
        <div class="translateButton">
          <el-tabs 
            class="special_tabs" 
            v-model="searchParams.companyType">
            <el-tab-pane :label="$t(`customerInfo.${companyType[0].name}`)" :name="companyType[0].value" />
            <el-tab-pane :label="$t(`customerInfo.${companyType[1].name}`)" :name="companyType[1].value" />
          </el-tabs>
        </div>
        <el-input 
          v-model="searchParams.companyName" 
          size="small" 
          style="width: 200px" 
          suffix-icon="el-icon-search" 
          :placeholder="$t('customerInfo.Input Company')"
        ></el-input>
        <el-autocomplete
          v-model="searchParams.userName"
          size="small"
          style="width: 200px"
          :fetch-suggestions="getManagerUserArraySelect"
          @select="(item) => searchParams.userName = item.username"
          :placeholder="$t('customerInfo.Input People')"
          :trigger-on-focus="false"
          suffix-icon="el-icon-search"
        >
          <template slot-scope="{ item }">
            <div style="display: flex; align-items: center; column-gap: 12px">
              <el-avatar size="small" :src="item.avatarFileUrl"></el-avatar>
              <span
                style="
                  width: 145px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ item.nickName }}</span
              >
            </div>
          </template>
        </el-autocomplete>
      </template>
      <template #searchAction>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addCustomerShow = !addCustomerShow"
        >
          {{ $t('customerInfo.New Customer') }}
        </el-button>
        <el-button
          size="small"
          icon="el-icon-download"
          @click="handleDownload"
        >
          {{ $t('customerInfo.Export') }}
        </el-button>
      </template>
      <template #tableColumn>
        <el-table-column label="No." type="index" width="70">
        </el-table-column>
        <el-table-column
          label="star"
          :filters="[
            { text: $t('home.Follow'), value: '1' },
            { text: $t('home.Nofollow'), value: '0' },
          ]"
          :filtered-value="filteredValue"
          :filter-multiple="false"
          min-width="60"
        >
          <template slot="header">
            <i
              class="iconfont icon-yishoucang"
              style="font-size: 16px; vertical-align: middle;color: #DF8F31;"
            ></i>
          </template>
          <template slot-scope="scope">
            <div class="star">
              <i
                class="iconfont"
                :class="
                  scope.row.favorite ? 'icon-yishoucang' : 'icon-shoucang'
                "
                @click.stop="
                  followCompanyHandler(scope.row)
                "
              ></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Logo')"
          width="140"
        >
          <template slot-scope="scope">
            <el-image
              style="cursor: pointer;width: 40px;"
              :src="scope.row.companyLogoUrl"
              fit="contain"
              @click="listPush(scope.row)"
            >
              <i class="el-icon-picture-outline" slot="error"></i>
            </el-image>
            <div v-if="scope.row.temp == 1" class="customTag">{{ $t('custom') }}</div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Company')"
          prop="aliasName"
          min-width="150"
          column-key="aliasName"
          sortable
        >
          <template slot-scope="scope">
            <div style="cursor: pointer" @click="listPush(scope.row)">
              <span>{{ scope.row.aliasName || scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Contact')"
          prop="contact"
          min-width="160"
        >
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Email')"
          prop="email"
          min-width="220"
        >
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Country')"
          sortable
          prop="country"
          min-width="120"
        >
          <template slot-scope="scope">
            {{ $isFindData(country, scope.row.country, 'value', 'key') }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('customerInfo.Admin')" min-width="160">
          <template slot-scope="scope">
            <div class="customerName">
              <member :info="scope.row.companyManager" :size="28"></member>
              <span>{{ scope.row.companyManager && scope.row.companyManager.nickName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Member')"
          prop="memberCount"
          min-width="100"
          sortable
        >
        </el-table-column>
        <el-table-column
          :label="$t('customerInfo.Order Total')"
          prop="orderCount"
          min-width="120"
          sortable
        >
        </el-table-column>
        <el-table-column fixed="right" :label="$t('customerInfo.Action')" min-width="90">
          <template slot-scope="scope">
            <div class="action">
              <div
                class="btn" 
                v-if="manageCompany"
                @click.stop="invite(scope.row)">
                <i class="iconfont icon-yaoqingyuangong"></i>
              </div>
              <el-dropdown
                trigger="click" 
                placement="bottom-start"
                @visible-change="visibleDropdownChange">
                <div
                  class="btn" 
                  :class="tableSelectedShow === scope.$index ? 'active' : ''"
                  @click="tableSelectedShow = scope.$index">
                  <i class="iconfont icon-Ellipsis"></i>
                </div>
                <el-dropdown-menu class="ec-popper" slot="dropdown">
                  <el-dropdown-item
                    class="popperMenuItem"
                    icon="iconfont icon-yincang2"
                    @click.native="showHide(scope.row)"
                  >{{ scope.row.hidden ? $t('home.Cancel')+$t('home.Hide') : $t('home.Hide')  }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    v-if="isSetGroup && searchParams.companyType != '2'"
                    icon="iconfont icon-zuqunshezhi"
                    @click.native="groupManagementId = `${scope.row.companyCustomerId}|${scope.row.companyId}`"
                  >{{ $t("home.set group") }}</el-dropdown-item>
                  <el-dropdown-item
                    v-if="scope.row.temp == 1"
                    class="popperMenuItem"
                    icon="iconfont icon-Vector"
                    @click.native="editCustomer(scope.row)"
                  >{{ $t("home.Edit") }}</el-dropdown-item>
                  <el-dropdown-item
                    class="popperMenuItem"
                    icon="iconfont icon-shanchu"
                    @click.native="removeCompanyHandler(scope.row.companyCustomerId)"
                  >{{ $t('home.Delete') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </template>
    </pageTable>
    <createCustomer
      :addCustomerShow.sync="addCustomerShow"
      :invitationShow.sync="invitationShow"
      @handleClose="addCustomerShow = !addCustomerShow"
      @openInvitationDialog="(val) => (invitationShow = val)"
    />
    <assignApproveList
      :approveListShow.sync="approveListShow1"
      :customerCompanyId="customerCompanyId"
      :allConnection="allConnection"
      @handleClose="(approveListShow1 = false), getCompanyArray('loading')"
      :func="'Invite'"
    />
    <groupManagement
      v-if="groupManagementId"
      :customerCompanyId.sync="groupManagementId"
      @close="groupManagementId = ''"
    ></groupManagement>
    <createCustomCustomer ref="createCustomCustomer" />
  </el-card>
</template>

<script>
import { Message, MessageBox } from 'element-ui';
import {
  getUserCustomList,
  deleteCustomerConnection,
  collectCustomer,
  unCollectCustomer,
  showCustomer,
  getCustomerUsers
} from '@/api/customer';
import store from '@/store';
import { mapGetters } from 'vuex';
import createCustomer from '@/components/createCustomer/createCustomer';
import assignApproveList from '@/components/assignApproveList/assignApproveList';
import createCustomCustomer from "@/components/createCustomer/createCustomCustomer.vue";

export default {
  name: 'CustomerList',
  components: {
    createCustomer,
    assignApproveList,
    createCustomCustomer
  },
  inject: ['reload'],
  data() {
    return {
      searchHeight: 0,
      searchParams: {
        companyName: "",
        userName: "",
        companyType: 'PURCHASER',
        currentPage: 1,
        pageSize: 10,
        followed: '',
        sortField: '',
        sortType: '',
        totalElement: 0
      },
      groupManagementId: "",
      tableSelectedShow: null,
      companyType: [],
      companyList: [],
      options: [],
      optionsUser: [],
      isGrid: false,
      isLoading: true,
      selectedKey: null,
      addCustomerShow: false,
      invitationShow: false,
      table: null,
      filteredValue: [],
      customerCompanyId: null,
      allConnection: null,
      approveListShow1: false
    }
  },
  methods: {
    editCustomer(item) {
      this.$refs.createCustomCustomer.open(item, 'edit');
    },
    visibleDropdownChange(val) {
      if(!val) {
        this.tableSelectedShow = null;
      }
    },
    handleDownload() {
      var tHeader = [
        this.$t('customerInfo.Company'),
        this.$t('customerInfo.Contact'),
        this.$t('customerInfo.Email'),
        this.$t('customerInfo.Country'),
        this.$t('customerInfo.Admin'),
        this.$t('customerInfo.Member'),
        this.$t('customerInfo.Order Total'),
      ];
      var filterVal = [
        'aliasName',
        'contact',
        'email',
        'country',
        'companyManager',
        'memberCount',
        'orderCount',
      ];
      var filename = this.$t("InformationCenter.Company");
      var data = this.formatJson(filterVal, this.companyList);
      this.$export_json_to_excel({
        header: tHeader,
        data,
        filename,
      });
    },
    formatJson(filterVal, tableData) {
      return tableData?.map((v) => {
        return filterVal?.map((j) => {
          if(j == 'country') {
            return this.$isFindData(this.country, v[j], 'value', 'key');
          }
          if(j == 'companyManager') {
            return v[j] && v[j].nickName;
          }
          return v[j];
        });
      });
    },
    tableRowClass({row, rowIndex}) {
      if(row.hidden) {
        return 'isTableoOpacity'
      } else {
        return "";
      }
    },
    getCompanyArray(mode) {
      try {
        if (mode !== 'loading') {
          this.isLoading = true;
        }
        getUserCustomList({
          currentPage: this.searchParams.currentPage,
          pageSize: this.searchParams.pageSize,
          companyName: this.searchParams.companyName,
          userName: this.searchParams.userName,
          companyType: this.searchParams.companyType,
          sortField: this.searchParams.sortField,
          sortType: this.searchParams.sortType,
          favorite: this.filteredValue[0]
        }).then((data) => {
          this.isLoading = false;
          this.searchParams.totalElement = data.total;
          this.companyList = data.list;
        }).finally(() => this.isLoading = false);
      } catch (error) {
        if (mode !== 'loading') {
          this.isLoading = false;
        }
      }
    },
    async getManagerUserArraySelect(name, cb) {
      if(!name) {
        cb([]);
        return;
      }
      getCustomerUsers({ 
        companyType: this.searchParams.companyType,
        name 
      }).then((data) => {
        cb(data.map(item => ({
          ...item,
          value: item.nickName
        })))
      })
    },
    clearHandler() {
      this.searchParams.companyName = "";
      this.searchParams.userName = "";
      this.searchParams.currentPage = 1;
      this.searchParams.pageSize = 10;
      this.searchParams.followed = '';
      this.filteredValue.splice(0, 1);
    },
    activePopover(key, index, item) {
      this.selectedKey = item?.companyId || item?.businessOrderId;
    },
    changePageHandler(page) {
      this.searchParams.currentPage = page;
      this.getCompanyArray();
    },
    changePageSizeHandler(pageSize) {
      this.searchParams.pageSize = pageSize;
      this.getCompanyArray();
    },
    async removeCompanyHandler(id, index) {
      MessageBox.confirm(
        this.$t('home.ConfirmRemoveConnection'),
        this.$t('home.Remove'),
        {
          confirmButtonText: this.$t('deleteEmployee.Confirmz'),
          cancelButtonText: this.$t('deleteEmployee.Cancel'),
          type: 'warning',
        }
      ).then(async () => {
        try {
          deleteCustomerConnection(id).then(() => {
            this.$message.success(this.$t("todo.Delete Successful"));
            this.getCompanyArray();
            this.reload(['Index']);
          })
        } catch (error) {
          console.error(error);
        }
      });
    },
    followCompanyHandler(row) {
      this.isLoading = true;
      if(row.favorite) {
        unCollectCustomer({ customerId: row.companyId }).then(() => {
          this.reload(['Index']);
          this.getCompanyArray();
        }).finally(() => this.isLoading = false);
        return;
      }
      collectCustomer({ customerId: row.companyId }).then(() => {
        this.reload(['Index']);
        this.getCompanyArray();
      }).finally(() => this.isLoading = false);
    },
    showHide(item) {
      showCustomer({ 
        customerId: item.companyId
      }, item.hidden).then(() => {
        this.$message.success(this.$t('memberList.Operation succeeded'));
        this.getCompanyArray();
        this.reload(['Index']);
      })
    },
    listPush(data) {
      this.$router.push({
        name: 'Customer',
        query: {
          companyId: data.companyId
        },
      });
    },
    filterStatus(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    sortChange(data) {
      const { order, prop } = data;
      if(order) {
        this.form.sortField = prop;
        this.form.sortType = order == "ascending" ? "asc" : "desc";
      } else {
        this.form.sortType = "";
        this.form.sortField = "";
      }
      this.getCompanyArray();
    },
    invite(item) {
      this.customerCompanyId = item.companyCustomerId;
      this.allConnection = item.userVOList;
      setTimeout(() => {
        this.approveListShow1 = true;
      }, 500);
    }
  },
  computed: {
    ...mapGetters([
      'companyCurrentType',
      'country',
      'manageCompany',
      "readApply",
      "applyType"
    ]),
    userStore() {
      return store.state.user;
    },
    isSetGroup() {
      return (
        this.companyCurrentType == "1"
      );
    },
  },
  async activated() {
    const query = this.$route.query;
    if (query?.findMore) {
      try {
        if(this.companyCurrentType) {
          this.getCompanyArray();
        }
      } catch (error) {}
    }
  },
  watch: {
    applyType(val) {
      this.getCompanyArray();
    },
    companyCurrentType: {
      handler() {
        const types = {
          '0': [{
            name: 'VENDOR',
            value: '1'
          }, {
            name: 'FORWARDER',
            value: '2'
          }],
          '1': [{
            name: 'PURCHASER',
            value: '0'
          }, {
            name: 'FORWARDER',
            value: '2'
          }],
          '2': [{
            name: 'PURCHASER',
            value: '0'
          }, {
            name: 'VENDOR',
            value: '1'
          }],
        };
        this.companyType = types[this.companyCurrentType];
        this.searchParams.companyType = types[this.companyCurrentType] && types[this.companyCurrentType][0].value;
        this.getCompanyArray();
      },
      immediate: true
    },
    'store.state.user.applyType'(val) {
      if (
        val.type == 'customerApply' &&
        val.approveStatus != 'APPROVE_FAIL'
      ) {
        this.getCompanyArray();
      }
    },
    'searchParams.companyType'() {
      if (this.searchParams.companyType) {
        this.getCompanyArray();
      }
    },
    filteredValue() {
      this.getCompanyArray();
    },
    readApply() {
      this.getCompanyArray();
    }
  },
};
</script>

<style lang="less" scoped>
.customTag {
  position: absolute;
  top: 10px;
  left: 40px;
  background: #f8ebe0;
  color: #FF8311;
  border-radius: 4px 4px 4px 0;
  font-size: 12px;
  padding: 3px 2px;
  line-height: 1;
}
</style>
